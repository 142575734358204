import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivationStart, Router } from '@angular/router';
import { filter, map } from 'rxjs';

interface IMetaFields {
    description?: string;
    image?: string;
    title?: string;
    url: string;
}

@Injectable({
    providedIn: 'root',
})
export class SEOService {
    private readonly defaults = {
        description:
            'Passbot.io is a secure, seamless password manager that integrates directly with popular workplace apps like Slack, Discord, and Microsoft Teams. Effortlessly manage credentials, 2FA, and team access without additional downloads or disruptions, ensuring robust security and streamlined productivity',
        title: 'Passbot',
        image: `${window.location.origin}/assets/images/passbot-logo-fill.png`,
        url: 'https://passbot.io',
    };

    constructor(private readonly metaService: Meta, private readonly router: Router) {
        this.listenToRouteChange();
    }

    private listenToRouteChange() {
        this.router.events
            .pipe(
                filter((event) => event instanceof ActivationStart),
                map((event) => event.snapshot),
            )
            .subscribe((snapshot) => {
                this.updateSeoFields({
                    ...(snapshot.data.meta || {}),
                    title: snapshot.data.meta?.title || snapshot.routeConfig?.title,
                    url: `${window.location.origin}${window.location.pathname}`,
                });
            });
    }

    private updateSeoFields(fields: IMetaFields) {
        // description
        this.metaService.updateTag({ name: 'description', content: fields.description || this.defaults.description });
        this.metaService.updateTag({ name: 'og:description', content: fields.description || this.defaults.description });
        this.metaService.updateTag({ name: 'twitter:description', content: fields.description || this.defaults.description });

        // title
        this.metaService.updateTag({ name: 'og:title', content: fields.title || this.defaults.title });
        this.metaService.updateTag({ name: 'twitter:title', content: fields.title || this.defaults.title });

        // image
        this.metaService.updateTag({ name: 'og:image', content: fields.image || this.defaults.image });
        this.metaService.updateTag({ name: 'twitter:image', content: fields.image || this.defaults.image });

        // url
        this.metaService.updateTag({ name: 'og:url', content: fields.url });
    }
}
