import { AfterViewInit, Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { RouterLink } from '@angular/router';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Industries } from '@passbot/shared';
import { MultiCurrencyPriceComponent } from '../multi-currency-price/multi-currency-price.component';

@Component({
    selector: 'passbot-index',
    templateUrl: './index.html',
    standalone: true,
    imports: [NgClass, CommonModule, RouterLink, ReactiveFormsModule, MultiCurrencyPriceComponent],
})
export class IndexComponent implements AfterViewInit {
    @ViewChildren('video') private readonly videoPlayers: QueryList<ElementRef<HTMLVideoElement>>;
    public openPoints: Record<number, boolean> = {};
    public showPrev = false;
    public interestRegisteredStatus = 0;
    public industries = Industries;
    public signupError: string;

    public interestForm = new FormGroup({
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.email, Validators.required]),
        company: new FormControl('', [Validators.required]),
        companySize: new FormControl('', [Validators.required]),
        industry: new FormControl('', [Validators.required]),
        workspace: new FormControl('', [Validators.required]),
    });

    constructor(private readonly httpClient: HttpClient) {}

    public ngAfterViewInit() {
        this.videoPlayers.forEach((videoPlayer) => {
            videoPlayer.nativeElement.muted = true;
            void videoPlayer.nativeElement.play();
        });
    }

    public togglePoints(index: number) {
        this.openPoints[index] = !this.openPoints[index];
    }

    public toggleShowPrev() {
        this.showPrev = !this.showPrev;
    }

    public resetForm() {
        this.interestForm.reset({});
        this.interestRegisteredStatus = 0;
    }

    public registerInterest() {
        this.interestRegisteredStatus = 1;

        this.httpClient.post('/api/signup/interest', this.interestForm.value).subscribe({
            next: () => {
                this.interestRegisteredStatus = 2;
            },
            error: (err: any) => {
                if (err.error.message.includes('already a list member')) {
                    this.signupError = `${this.interestForm.controls.email.value} is already registered`;
                } else {
                    this.signupError = 'There has been an error, please try again later.';
                }

                this.interestRegisteredStatus = 0;
            },
        });
    }
}
