

<section id="header" class="py-20 sm:py-0 sm:h-[50vh]  md:h-[85vh] bg-gradient-to-b from-slate-900 to-slate-950 relative w-full">
    <div class="container mx-auto h-full">
        <div class="flex items-center ju h-full space-x-20 px-2 sm:px-8">
            <div class="space-y-8 flex flex-col justify-center items-center md:items-start flex-1 px-2 lg:px-0">
                <div class="text-4xl md:text-5xl font-inter font-bold bg-gradient-to-b from-orange-500 to-brand bg-clip-text text-transparent">
                    <img class="h-14 w-auto inline-block lg:hidden" src="/assets/images/passbot-logo.svg"/> Passbot.io
                </div>
                <div class="text-4xl sm:text-5xl md:text-6xl lg:text-8xl font-bold text-white text-center md:text-left">
                    Seamless<span class="text-brand">,</span> Secure<span class="text-brand">,</span><br/> No Downloads Needed<span class="text-brand">.</span><br/>
                </div>
                <div class="space-y-8 pt-4 text-center md:text-left">
                    <div class="text-white/70 text-xl sm:text-4xl font-bold">
                        Password and 2FA Management for Your Business, Integrated Within your Workspace<span class="text-brand">.</span>
                    </div>
                    <div class="text-lg  sm:text-xl md:text-4xl text-white space-x-2 md:space-x-4 lg:space-x-6 flex justify-center md:justify-start">
                        <div class="space-y-2 group transition-all relative">
                            <div class="border border-slack rounded-lg w-10 h-10 sm:w-14 sm:h-14 md:w-20 md:h-20 flex items-center justify-center text-center group-hover:bg-slack transition-all">
                                <i class="fa-brands fa-slack-hash text-slack group-hover:text-slate-950 transition-all"></i>
                            </div>
                            <div class="absolute left-[50%] -ml-[22.5px] font-normal text-sm text-slack font-inter text-center invisible group-hover:visible">SLACK</div>
                        </div>
                        <div class="space-y-2 group transition-all relative">
                            <div class="border border-discord w-10 h-10 sm:w-14 sm:h-14 md:w-20 md:h-20 flex items-center justify-center text-center rounded-lg group-hover:bg-discord transition-all">
                                <i class="fa-brands fa-discord text-discord group-hover:text-slate-950 transition-all"></i>
                            </div>
                            <div class="absolute left-[50%] -ml-[31.5px] font-normal text-sm text-discord font-inter text-center invisible group-hover:visible">DISCORD</div>
                        </div>

                        <div class="space-y-2 group transition-all relative">
                            <div class="border border-whatsapp rounded-lg w-10 h-10 sm:w-14 sm:h-14 md:w-20 md:h-20 flex items-center justify-center group-hover:bg-whatsapp transition-all">
                                <i class="fa-brands fa-whatsapp text-whatsapp group-hover:text-slate-950 transition-all"></i>
                            </div>
                            <div class="absolute left-[50%] -ml-[38.5px] font-normal text-sm text-whatsapp font-inter text-center invisible group-hover:visible">WHATSAPP</div>
                        </div>

                        <div class="space-y-2 group transition-all relative">
                            <div class="border border-google rounded-lg w-10 h-10 sm:w-14 sm:h-14 md:w-20 md:h-20 flex items-center justify-center text-center group-hover:bg-google transition-all">
                                <i class="fa-brands fa-google text-google group-hover:text-slate-950 transition-all"></i>
                            </div>
                            <div class="absolute left-[50%] -ml-[29px] font-normal text-sm text-google font-inter text-center invisible group-hover:visible">GOOGLE</div>
                        </div>

                        <div class="space-y-2 group transition-all relative">
                            <div class="border border-microsoft rounded-lg w-10 h-10 sm:w-14 sm:h-14 md:w-20 md:h-20 flex items-center justify-center text-center group-hover:bg-microsoft">
                                <i class="fa-brands fa-microsoft text-microsoft group-hover:text-slate-950"></i>
                            </div>
                            <div class="absolute left-[50%] -ml-[41.5px] font-normal text-sm text-microsoft font-inter text-center invisible group-hover:visible">MICROSOFT</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-col justify-center hidden lg:flex">
                <img class="xl:h-96 h-60 w-auto block" src="/assets/images/passbot-logo.svg"/>
            </div>

        </div>
    </div>



</section>

<section class="py-20 max-w-[1100px] mx-auto px-2 xl:px-0" id="features">
    <h2 class="font-inter font-bold text-center text-5xl">Key Features<span class="text-brand">.</span></h2>
    <div class="mt-12 space-y-14">
        <div class="bg-white rounded-xl flex overflow-hidden flex-col-reverse lg:flex-row flex-nowrap">
            <div class="flex-[1_1] p-8 space-y-8 flex-col flex">
                <h2 class="font-bold font-inter text-3xl"> <span class="text-brand">1.</span> Effortless Workspace Integration</h2>
                <div class="text-lg flex-1 relative">
                    <ul class="space-y-4 text-gray-500 list-disc list-inside marker:text-brand">
                        <li>
                            <span class="text-brand font-semibold">Native App Integration:</span> Passbot integrates directly with popular workplace apps like Slack, Discord, Microsoft Teams, Google Workspace, and more, allowing your team to manage passwords and credentials without leaving the preferred communication tools they already use.
                        </li>
                        <li>
                            <span class="text-brand font-semibold">No Extra Downloads:</span> There’s no need to download additional apps or software, everything is handled within your existing workflow, ensuring a streamlined and frictionless experience.
                        </li>
                    </ul>
                    <div [ngClass]="{ 'h-full': openPoints[1], 'h-0': !openPoints[1] }" class="absolute w-full transition-all bg-white bottom-0 overflow-hidden">
                        <div class="space-y-2 text-gray-500 text-sm">
                            <p>Seamlessly weave Passbot directly into the workplace apps your team already uses, like Slack, Discord, Microsoft Teams, and Google Workspace, with no extra downloads or disruptions. Manage passwords and credentials natively within your existing workflow, making secure access as easy as sending a message.</p>
                            <p>Passbot enhances your existing tools with robust security and convenience, ensuring maximum productivity and minimal friction. Focus on your work with confidence, knowing Passbot is quietly and securely managing your credentials in the background.</p>
                        </div>
                    </div>
                </div>
                <div class="text-sm font-semibold" (click)="togglePoints(1)">
                    MORE INFORMATION <i [ngClass]="{ 'fa-chevron-circle-down': openPoints[1], 'fa-chevron-circle-up': !openPoints[1] }" class="fa-solid text-brand"></i>
                </div>
            </div>
            <div class="aspect-square w-full lg:w-[400px] bg-slate-900 flex items-center justify-center">
                <video #video class="w-full h-full aspect-square object-cover" autoplay="autoplay" loop muted="true" playsinline>
                    <source src="/assets/videos/slack-integration-enc.mp4" type="video/mp4" />
                </video>
            </div>

        </div>


        <div class="bg-white rounded-xl flex flex-col-reverse lg:flex-row-reverse overflow-hidden">
            <div class="flex-1 p-8 space-y-8 flex-col flex">
                <h2 class="font-bold font-inter text-3xl"> <span class="text-brand">2.</span> Automatic User Onboarding</h2>
                <div class="text-lg flex-grow-1 flex-shrink relative min-h-[150px]">
                    <ul class="space-y-4 text-gray-500 list-disc list-inside marker:text-brand">
                        <li>
                            <span class="text-brand font-semibold">Admin-Controlled Enrollment:</span> Easily onboard users through the admin panel by selecting them from your existing workplace app user list.
                        </li>
                        <li>
                            <span class="text-brand font-semibold">Bot-Driven Setup:</span> The Passbot automatically guides users through the setup process within their chosen app, simplifying the onboarding experience.
                        </li>
                    </ul>
                    <div [ngClass]="{ 'h-full': openPoints[2], 'h-0': !openPoints[2] }" class="absolute w-full transition-all bg-white bottom-0 overflow-hidden">
                        <div class="space-y-2 text-gray-500 text-sm">
                            <p>Simplify your team's onboarding process with Passbot's intuitive admin panel. Easily add users directly from your existing workplace apps like Slack and Discord—no need for lengthy setup or additional software. Once a user is selected, Passbot bot takes over, guiding them through a quick, automated setup within the app they’re already familiar with.</p>
                            <p>This seamless onboarding experience ensures your team is up and running in minutes, not hours. Passbot handles the heavy lifting, allowing you to focus on your core business while ensuring every user is securely integrated into your password management system from day one.</p>
                        </div>
                    </div>
                </div>
                <div class="text-sm font-semibold" (click)="togglePoints(2)">
                    MORE INFORMATION <i [ngClass]="{ 'fa-chevron-circle-down': openPoints[2], 'fa-chevron-circle-up': !openPoints[2] }" class="fa-solid text-brand"></i>
                </div>
            </div>
            <div class="aspect-square w-full lg:w-[400px] bg-slate-900 flex items-center justify-center">
                <video #video class="w-full h-full aspect-square object-cover" autoplay loop muted playsinline>
                    <source src="/assets/videos/onboarding-enc.mp4" type="video/mp4" />
                </video>
            </div>

        </div>

        <div class="bg-white rounded-xl flex flex-col-reverse lg:flex-row flex-nowrap overflow-hidden">
            <div class="flex-1 p-8 space-y-8 flex-col flex">
                <h2 class="font-bold font-inter text-3xl"> <span class="text-brand">3.</span> Advanced Personal Security</h2>
                <div class="text-lg flex-grow-1 flex-shrink relative min-h-[150px]">
                    <ul class="space-y-4 text-gray-500 list-disc list-inside marker:text-brand">
                        <li>
                            <span class="text-brand font-semibold">Biometric and Physical Authentication:</span> Protect your credentials with the latest technology, utilizing biometric sensors (like fingerprint or face ID) and physical security keys (like YubiKey).
                        </li>
                        <li>
                            <span class="text-brand font-semibold">Multi-Device Support:</span> Register and authenticate via mobile devices, local devices, or USB devices for secure and flexible access.
                        </li>
                    </ul>
                    <div [ngClass]="{ 'h-full': openPoints[3], 'h-0': !openPoints[3] }" class="absolute w-full transition-all bg-white bottom-0 overflow-hidden">
                        <div class="space-y-2 text-gray-500 text-sm">
                            <p>Protect your credentials with cutting-edge security that leverages the latest in biometric and physical authentication technology. Passbot ensures that only authorized users can access sensitive information by requiring secure methods like fingerprint scans, face recognition, or physical security keys. This added layer of protection keeps your data safe, whether accessed from a mobile device, desktop, or USB key.</p>
                            <p>With Passbot, your team's credentials are guarded by the highest standards, ensuring that your business stays secure in an increasingly complex digital landscape.</p>
                        </div>
                    </div>
                </div>
                <div class="text-sm font-semibold" (click)="togglePoints(3)">
                    MORE INFORMATION <i [ngClass]="{ 'fa-chevron-circle-down': openPoints[3], 'fa-chevron-circle-up': !openPoints[3] }" class="fa-solid text-brand"></i>
                </div>
            </div>
            <div class="aspect-square w-full lg:w-[400px] bg-slate-900 flex items-center justify-center">
                <video #video class="w-full h-full aspect-square object-cover" autoplay loop muted playsinline>
                    <source src="/assets/videos/fingerprint-auth-enc.mp4" type="video/mp4" />
                </video>
            </div>

        </div>


        <div class="bg-white rounded-xl flex flex-col-reverse lg:flex-row-reverse flex-nowrap overflow-hidden">
            <div class="flex-1 p-8 space-y-8 flex-col flex">
                <h2 class="font-bold font-inter text-3xl"> <span class="text-brand">4.</span> Flexible Vault Management</h2>
                <div class="text-lg flex-grow-1 flex-shrink relative min-h-[170px]">
                    <ul class="space-y-4 text-gray-500 list-disc list-inside marker:text-brand">
                        <li>
                            <span class="text-brand font-semibold">Unlimited Vault Creation:</span> Organize credentials into as many vaults as your team needs, with no restrictions on the number of vaults. Credentials can also be assigned to more than one vault at a time.
                        </li>
                        <li>
                            <span class="text-brand font-semibold">Granular Access Control:</span> Assign roles to users within each vault (Read, Read & Write, Admin) to control who can view, edit, or manage access to credentials.
                        </li>
                    </ul>
                    <div [ngClass]="{ 'h-full': openPoints[4], 'h-0': !openPoints[4] }" class="absolute w-full transition-all bg-white bottom-0 overflow-hidden">
                        <div class="space-y-2 text-gray-500 text-sm">
                            <p>Organize and secure your team’s credentials with Passbot’s versatile vault management system. Create as many vaults as your organization needs, grouping credentials by team, project, or department. With flexible access controls, you can assign roles like Read, Read & Write, or Admin, ensuring the right people have the right level of access to each vault.</p>
                            <p>Passbot’s user-friendly interface makes it easy to manage vaults and permissions, giving you full control over who can view, edit, or manage your sensitive information. Whether you’re a small team or a large enterprise, Passbot adapts to your needs, offering a streamlined way to keep your credentials organized and secure.</p>
                        </div>
                    </div>
                </div>
                <div class="text-sm font-semibold" (click)="togglePoints(4)">
                    MORE INFORMATION <i [ngClass]="{ 'fa-chevron-circle-down': openPoints[4], 'fa-chevron-circle-up': !openPoints[4] }" class="fa-solid text-brand"></i>
                </div>
            </div>
            <div class="aspect-square w-full lg:w-[400px] bg-slate-900 flex items-center justify-center">
                <video #video class="w-full h-full aspect-square object-cover" autoplay loop muted playsinline>
                    <source src="/assets/videos/vault-perms-enc.mp4" type="video/mp4" />
                </video>
            </div>

        </div>


        <div class="bg-white rounded-xl flex flex-col-reverse lg:flex-row flex-nowrap overflow-hidden">
            <div class="flex-[1_1] p-8 space-y-8 flex-col flex">
                <h2 class="font-bold font-inter text-3xl"> <span class="text-brand">5.</span> Real-Time 2FA Generation</h2>
                <div class="text-lg flex-1 relative min-h-[150px]">
                    <ul class="space-y-4 text-gray-500 list-disc list-inside marker:text-brand">
                        <li>
                            <span class="text-brand font-semibold">Instant OTP Generation:</span> The Passbot holds and generates 2FA TOTP codes on demand, in real-time, providing secure access whenever it’s needed. No need to ask a friend or colleague for the 2FA code for shared accounts.
                        </li>
                        <li>
                            <span class="text-brand font-semibold">Bot as Keyholder:</span> The bot securely stores TOTP secrets and generates codes from your vaults. This eliminates the need for codes to be tied to individual devices or personal accounts, making it easier for your team to access critical systems without the typical hurdles.
                        </li>
                    </ul>
                    <div [ngClass]="{ 'h-full': openPoints[5], 'h-0': !openPoints[5] }" class="absolute w-full transition-all bg-white bottom-0 overflow-hidden">
                        <div class="space-y-2 text-gray-500 text-sm">
                            <p>Enhance your security with Passbot’s real-time 2FA management, eliminating the hassle of assigning 2FA codes to individual devices or personal accounts. Our bot securely stores and generates 2FA codes on demand, ensuring your team can access critical systems quickly and securely without relying on specific devices or users. This approach reduces the risks associated with lost or unavailable devices, streamlining your security processes.</p>
                            <p>Passbot’s 2FA management integrates seamlessly into your daily operations, providing an additional layer of security that’s both powerful and convenient. Every access attempt is secured in real-time, ensuring your accounts are protected while your team maintains a smooth, uninterrupted workflow.</p>
                        </div>
                    </div>
                </div>
                <div class="text-sm font-semibold" (click)="togglePoints(5)">
                    MORE INFORMATION <i [ngClass]="{ 'fa-chevron-circle-down': openPoints[5], 'fa-chevron-circle-up': !openPoints[5] }" class="fa-solid text-brand"></i>
                </div>
            </div>
            <div class="aspect-square w-full lg:w-[400px] bg-slate-900 flex items-center justify-center">
                <video #video class="w-full h-full aspect-square object-cover" autoplay loop muted playsinline>
                    <source src="/assets/videos/2fa-request-enc.mp4" type="video/mp4" />
                </video>
            </div>
        </div>

        <div class="bg-white rounded-xl flex flex-col-reverse lg:flex-row-reverse flex-nowrap overflow-hidden">
            <div class="flex-1 p-8 space-y-8 flex-col flex">
                <h2 class="font-bold font-inter text-3xl"> <span class="text-brand">6.</span> Comprehensive Audit Logging</h2>
                <div class="text-lg flex-1 relative min-h-[185px]">
                    <ul class="space-y-4 text-gray-500 list-disc list-inside marker:text-brand">
                        <li>
                            <span class="text-brand font-semibold">Full Transparency:</span> Keep track of every action within your vaults with detailed logs that show when passwords are requested, OTP codes generated, credentials edited, users added or removed, and more.
                        </li>
                        <li>
                            <span class="text-brand font-semibold">Enhanced Security Monitoring:</span> Use the audit logs to monitor activity and ensure compliance with your organization’s security policies.
                        </li>
                    </ul>
                    <div [ngClass]="{ 'h-full': openPoints[6], 'h-0': !openPoints[6] }" class="absolute w-full transition-all bg-white bottom-0 overflow-hidden">
                        <div class="space-y-2 text-gray-500 text-sm">
                            <p>Gain full visibility into your team’s activity with Passbot’s comprehensive audit logging. Every action within the platform—whether it’s accessing a password, generating a 2FA code, creating or editing credentials, or managing user permissions—is meticulously tracked and recorded. This level of transparency ensures you have a complete record of who did what and when, providing critical insights into your security practices.</p>
                            <p>Passbot’s audit logs not only enhance security but also help you maintain compliance with industry regulations and internal policies. With easy access to detailed logs, you can quickly identify and respond to potential security incidents, ensuring your organization stays protected and accountable at all times.</p>
                        </div>
                    </div>
                </div>
                <div class="text-sm font-semibold" (click)="togglePoints(6)">
                    MORE INFORMATION <i [ngClass]="{ 'fa-chevron-circle-down': openPoints[6], 'fa-chevron-circle-up': !openPoints[6] }" class="fa-solid text-brand"></i>
                </div>
            </div>
            <div class="aspect-square w-full lg:w-[400px] bg-slate-900 flex items-center justify-center">
                <video #video class="w-full h-full aspect-square object-cover" autoplay loop muted playsinline>
                    <source src="/assets/videos/audit-log-enc.mp4" type="video/mp4" />
                </video>
            </div>
        </div>

        <div class="bg-white rounded-xl flex flex-col-reverse lg:flex-row flex-nowrap overflow-hidden">
            <div class="flex-[1_1] p-8 space-y-8 flex-col flex ">
                <h2 class="font-bold font-inter text-3xl"> <span class="text-brand">7.</span> Security Dashboard and Insights</h2>
                <div class="text-lg flex-1 relative min-h-[185px]">
                    <ul class="space-y-4 text-gray-500 list-disc list-inside marker:text-brand">
                        <li>
                            <span class="text-brand font-semibold">Real-Time Security Analysis:</span> Get in-depth insights into the strength of your passwords, assess susceptibility to brute force attacks, and receive an overall account health score with actionable recommendations.
                        </li>
                        <li>
                            <span class="text-brand font-semibold">Built-In Password Generator:</span> Easily create strong, secure passwords within the app to replace vulnerable ones, ensuring your team’s credentials remain protected and up to date.
                        </li>
                    </ul>
                    <div [ngClass]="{ 'h-full': openPoints[8], 'h-0': !openPoints[8] }" class="absolute w-full transition-all bg-white bottom-0 overflow-hidden">
                        <div class="space-y-2 text-gray-500 text-sm">
                            <p>
                                Empower your team with Passbot’s comprehensive security dashboard, offering real-time insights into the strength and security of your credentials. Our dashboard evaluates each password’s resilience against threats like brute force attacks and provides an overall account health score. This allows users to quickly identify vulnerabilities and take action to improve their security standing.
                            </p>
                            <p>With tailored suggestions for enhancing password security and the ability to generate strong, secure passwords directly within the app, Passbot ensures your team’s credentials are always protected. Stay ahead of potential risks and maintain optimal security with a clear, actionable overview of your account’s health.</p>
                        </div>
                    </div>
                </div>
                <div class="text-sm font-semibold" (click)="togglePoints(8)">
                    MORE INFORMATION <i [ngClass]="{ 'fa-chevron-circle-down': openPoints[8], 'fa-chevron-circle-up': !openPoints[8] }" class="fa-solid text-brand"></i>
                </div>
            </div>
            <div class="aspect-square w-full lg:w-[400px] bg-slate-900 flex items-center justify-center">
                <video #video class="w-full h-full aspect-square object-cover" autoplay loop muted playsinline>
                    <source src="/assets/videos/health-check-enc.mp4" type="video/mp4" />
                </video>
            </div>
        </div>

        <div class="bg-white rounded-xl flex flex-col-reverse lg:flex-row-reverse flex-nowrap overflow-hidden">
            <div class="flex-1 p-8 space-y-8 flex-col flex">
                <h2 class="font-bold font-inter text-3xl"> <span class="text-brand">8.</span> Simple User Experience</h2>
                <div class="text-lg flex-[1_1] relative min-h-[150px]">
                    <ul class="space-y-4 text-gray-500 list-disc list-inside marker:text-brand">
                        <li>
                            <span class="text-brand font-semibold">Intuitive Interface:</span> Designed for ease of use, Passbot’s interface ensures that even non-technical users can manage their credentials with confidence.
                        </li>
                        <li>
                            <span class="text-brand font-semibold">Quick Setup:</span> With minimal setup required, your team can start using Passbot in minutes, not hours.
                        </li>
                    </ul>
                    <div [ngClass]="{ 'h-full': openPoints[9], 'h-0': !openPoints[9] }" class="absolute w-full transition-all bg-white bottom-0 overflow-hidden">
                        <div class="space-y-2 text-gray-500 text-sm">
                            <p>Passbot is designed with simplicity in mind, offering a user-friendly interface that makes managing credentials straightforward for everyone, regardless of technical expertise. From onboarding to daily use, every feature is intuitive and easy to navigate, allowing your team to focus on their work without getting bogged down by complex processes.</p>
                            <p>With Passbot, security doesn’t come at the cost of usability. Our streamlined design ensures that even advanced features, like vault management and 2FA generation, are accessible and effortless to use. Enjoy powerful security tools wrapped in an experience that’s as simple as it is effective.</p>
                        </div>
                    </div>
                </div>
                <div class="text-sm font-semibold" (click)="togglePoints(9)">
                    MORE INFORMATION <i [ngClass]="{ 'fa-chevron-circle-down': openPoints[9], 'fa-chevron-circle-up': !openPoints[9] }" class="fa-solid text-brand"></i>
                </div>
            </div>
            <div class="aspect-square w-full lg:w-[400px] bg-slate-900 flex items-center justify-center">
                <video #video class="w-full h-full aspect-square object-cover" autoplay loop muted playsinline>
                    <source src="/assets/videos/ease-use-enc.mp4" type="video/mp4" />
                </video>
            </div>
        </div>

    </div>
</section>

<section class="bg-slate-900 py-20 text-white dark" id="milestones">
    <div class="container mx-auto">
        <h2 class="font-inter font-bold text-center text-5xl mb-20">Progress & Milestones<span class="text-brand">.</span></h2>
        <div class="flex justify-center items-center relative flex-col">
            <div class="w-full top-0 bg-gradient-to-t from-slate-900/0 via-slate-900/80 to-slate-900 z-10" [ngClass]="{'absolute h-60': !showPrev}">
                <div class="w-full text-center">
                    <div class="border border-b border-slate-500 rounded-full px-4 py-2 flex items-center justify-center w-60 mx-auto" (click)="toggleShowPrev()">
                        {{ showPrev ? 'Hide' : 'See' }} Previous Milestones <i class="fa-light text-brand ml-2" [ngClass]="{'fa-chevron-circle-up': !showPrev, 'fa-chevron-circle-down': showPrev }"></i>
                    </div>
                </div>
            </div>
            <div class="relative overflow-y-hidden w-full mx-auto pl-28 pr-2 lg:pr-0 lg:pl-40">
                <ol class="relative border-s border-gray-200 dark:border-gray-700 max-w-[1100px] mx-auto transition-all duration-500 " [ngClass]="{'-mt-[1000px]': !showPrev, 'mt-1': showPrev}">
                    <li class="mb-10 ms-6 relative">
                        <span class="absolute right-[calc(100%+10px)] inline-block pr-10 top-2">
                           <time class="block mb-2 text-xs md:text-sm font-normal leading-none text-gray-400 whitespace-nowrap">March 2023</time>
                        </span>
                        <span class="absolute flex items-center justify-center w-6 h-6 rounded-full top-1 -start-9 ring-8 ring-white dark:ring-gray-900 dark:bg-slate-900">
                           <i class="fa-solid fa-check-circle text-green-500"></i>
                        </span>
                        <h3 class="flex items-center mb-2 text-lg font-semibold text-gray-900 dark:text-white">Project Planning and Initial Setup</h3>
                        <div class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                            <ul class="space-y-2 text-gray-400 text-sm sm:text-normal list-disc list-inside marker:text-gray-200">
                                <li>
                                    Define product requirements and technical specifications.
                                </li>
                                <li>
                                    Set up development environment and choose technology stack (frontend, backend, bots integration, etc.).
                                </li>
                                <li>
                                    Establish project roadmap and timeline.
                                </li>
                            </ul>
                        </div>
                        <!--                    <a href="#" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-100 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700"><svg class="w-3.5 h-3.5 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">-->
                        <!--                        <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z"/>-->
                        <!--                        <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"/>-->
                        <!--                    </svg> Download ZIP</a>-->
                    </li>
                    <li class="mb-10 ms-6 relative">
                        <span class="absolute right-[calc(100%+10px)] inline-block pr-10 top-2">
                           <time class="block mb-2 text-xs md:text-sm font-normal leading-none text-gray-400 whitespace-nowrap">June 2023</time>
                        </span>
                        <span class="absolute flex items-center justify-center w-6 h-6 rounded-full top-1 -start-9 ring-8 ring-white dark:ring-gray-900 dark:bg-slate-900">
                           <i class="fa-solid fa-check-circle text-green-500"></i>
                        </span>
                        <h3 class="flex items-center mb-2 text-lg font-semibold text-gray-900 dark:text-white">Core Infrastructure & API Development</h3>
                        <div class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                            <ul class="space-y-2 text-gray-400 text-sm sm:text-normal list-disc list-inside marker:text-gray-200">
                                <li>
                                    Develop the core infrastructure, including backend servers, databases, and API architecture.
                                </li>
                                <li>
                                    Implement user authentication and security protocols.
                                </li>
                                <li>
                                    Set up database for credential storage, vault management, and encryption.
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="mb-10 ms-6 relative">
                        <span class="absolute right-[calc(100%+10px)] inline-block pr-10 top-2">
                           <time class="block mb-2 text-xs md:text-sm font-normal leading-none text-gray-400 whitespace-nowrap">August 2023</time>
                        </span>
                        <span class="absolute flex items-center justify-center w-6 h-6 rounded-full top-1 -start-9 ring-8 ring-white dark:ring-gray-900 dark:bg-slate-900">
                           <i class="fa-solid fa-check-circle text-green-500"></i>
                        </span>
                        <h3 class="flex items-center mb-2 text-lg font-semibold text-gray-900 dark:text-white">Bot infrastructure</h3>
                        <div class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                            <ul class="space-y-2 text-gray-400 text-sm sm:text-normal list-disc list-inside marker:text-gray-200">
                                <li>
                                    Build onboarding bot workflow for new user invitations and automatic account setup.
                                </li>
                                <li>
                                    Develop messaging and interaction handling for requesting passwords, 2FA codes, and other features.
                                </li>
                                <li>
                                    Build abstracted service functionality.
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li class="mb-10 ms-6 relative">
                        <span class="absolute right-[calc(100%+10px)] inline-block pr-10 top-2">
                           <time class="block mb-2 text-xs md:text-sm font-normal leading-none text-gray-400 whitespace-nowrap">November 2023</time>
                        </span>
                        <span class="absolute flex items-center justify-center w-6 h-6 rounded-full top-1 -start-9 ring-8 ring-white dark:ring-gray-900 dark:bg-slate-900">
                           <i class="fa-solid fa-check-circle text-green-500"></i>
                        </span>
                        <h3 class="flex items-center mb-2 text-lg font-semibold text-gray-900 dark:text-white">Vault & Credential Management</h3>
                        <div class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                            <ul class="space-y-2 text-gray-400 text-sm sm:text-normal list-disc list-inside marker:text-gray-200">
                                <li>
                                    Build vault creation and management functionality (organizing credentials by teams, projects, etc.).
                                </li>
                                <li>
                                    Develop role-based access control (Read, Read & Write, Admin) for vaults.
                                </li>
                                <li>
                                    Implement real-time credential sharing, editing, and deletion functionality.
                                </li>
                                <li>
                                    Test vault and credential management across different user roles.
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li class="mb-10 ms-6 relative">
                        <span class="absolute right-[calc(100%+10px)] inline-block pr-10 top-2">
                           <time class="block mb-2 text-xs md:text-sm font-normal leading-none text-gray-400 whitespace-nowrap">January 2024</time>
                        </span>
                        <span class="absolute flex items-center justify-center w-6 h-6 rounded-full top-1 -start-9 ring-8 ring-white dark:ring-gray-900 dark:bg-slate-900">
                           <i class="fa-solid fa-check-circle text-green-500"></i>
                        </span>
                        <h3 class="flex items-center mb-2 text-lg font-semibold text-gray-900 dark:text-white">2FA and OTP Code Management</h3>
                        <div class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                            <ul class="space-y-2 text-gray-400 text-sm sm:text-normal list-disc list-inside marker:text-gray-200">
                                <li>
                                    Implement real-time TOTP generation and OTP management.
                                </li>
                                <li>
                                    Build the ability for the bot to act as the keyholder for generating and sharing OTP codes.
                                </li>
                                <li>
                                    Ensure 2FA functionality works across all integrated workplace apps.
                                </li>
                                <li>
                                    Test the process of generating and distributing OTP codes securely.
                                </li>
                            </ul>
                        </div>
                    </li>


                    <li class="mb-10 ms-6 relative">
                        <span class="absolute right-[calc(100%+10px)] inline-block pr-10 top-2">
                           <time class="block mb-2 text-xs md:text-sm font-normal leading-none text-gray-400 whitespace-nowrap">March 2024</time>
                        </span>
                        <span class="absolute flex items-center justify-center w-6 h-6 rounded-full top-1 -start-9 ring-8 ring-white dark:ring-gray-900 dark:bg-slate-900">
                           <i class="fa-solid fa-check-circle text-green-500"></i>
                        </span>
                        <h3 class="flex items-center mb-2 text-lg font-semibold text-gray-900 dark:text-white">Security Dashboard & Reporting</h3>
                        <div class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                            <ul class="space-y-2 text-gray-400 text-sm sm:text-normal list-disc list-inside marker:text-gray-200">
                                <li>
                                    Build the security dashboard that provides insights into password strength, susceptibility to attacks, and overall health scores.
                                </li>
                                <li>
                                    Develop actionable recommendations for improving password security.
                                </li>
                                <li>
                                    Integrate password generation functionality into the dashboard.
                                </li>
                                <li>
                                    Implement reporting and audit logs to track all activities, including password requests, 2FA generation, and credential edits.
                                </li>
                            </ul>
                        </div>
                    </li>


                    <li class="mb-10 ms-6 relative">
                        <span class="absolute right-[calc(100%+10px)] inline-block pr-10 top-2">
                           <time class="block mb-2 text-xs md:text-sm font-normal leading-none text-gray-400 whitespace-nowrap">June 2024</time>
                        </span>
                        <span class="absolute flex items-center justify-center w-6 h-6 rounded-full top-1 -start-9 ring-8 ring-white dark:ring-gray-900 dark:bg-slate-900">
                           <i class="fa-solid fa-check-circle text-green-500"></i>
                        </span>
                        <h3 class="flex items-center mb-2 text-lg font-semibold text-gray-900 dark:text-white">Security Dashboard & Reporting</h3>
                        <div class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                            <ul class="space-y-2 text-gray-400 text-sm sm:text-normal list-disc list-inside marker:text-gray-200">
                                <li>
                                    Design and implement a simple, intuitive user interface for the Admin Panel.
                                </li>
                                <li>
                                    Ensure easy navigation for onboarding, managing vaults, and security insights.
                                </li>
                                <li>
                                    Test for a seamless user experience across mobile, desktop, and workplace apps.
                                </li>
                                <li>
                                    Gather user feedback from early beta users to iterate and improve UI/UX.
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li class="mb-10 ms-6 relative">
                        <span class="absolute right-[calc(100%+10px)] inline-block pr-10 top-2">
                           <time class="block mb-2 text-xs md:text-sm font-normal leading-none text-gray-400 whitespace-nowrap">August 2024</time>
                        </span>
                        <span class="absolute flex items-center justify-center w-6 h-6 rounded-full top-1 -start-9 ring-8 ring-white dark:ring-gray-900 dark:bg-slate-900">
                           <i class="fa-solid fa-check-circle text-green-500"></i>
                        </span>
                        <h3 class="flex items-center mb-2 text-lg font-semibold text-gray-900 dark:text-white">Discord Integration<span class="text-sm font-medium me-2 px-2.5 py-0.5 rounded bg-brand/30 text-brand ms-3">Latest</span></h3>
                        <div class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                            <ul class="space-y-2 text-gray-400 text-sm sm:text-normal list-disc list-inside marker:text-gray-200">
                                <li>
                                    Build out Discord integration with the Bot service.
                                </li>
                                <li>
                                    Research and implement best UX flow for Discord integration.
                                </li>
                                <li>
                                    Integrate Discord platform onboarding and OAuth flow.
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li class="mb-10 ms-6 relative">
                        <span class="absolute right-[calc(100%+10px)] inline-block pr-10 top-2">
                           <time class="block mb-2 text-xs md:text-sm font-normal leading-none text-gray-400 whitespace-nowrap">October 2024</time>
                        </span>
                        <span class="absolute flex items-center justify-center w-6 h-6 rounded-full top-1 -start-9 ring-8 ring-white dark:ring-gray-900 dark:bg-slate-900">
                           <i class="fa-regular text-lg fa-calendar-day text-gray-400"></i>
                        </span>
                        <h3 class="flex items-center mb-2 text-lg font-semibold text-gray-900 dark:text-white">Slack Integration</h3>
                        <div class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                            <ul class="space-y-2 text-gray-400 text-sm sm:text-normal list-disc list-inside marker:text-gray-200">
                                <li>
                                    Build out Slack integration with the Bot service.
                                </li>
                                <li>
                                    Research and implement best UX flow for Slack integration.
                                </li>
                                <li>
                                    Integrate Slack platform onboarding and OAuth flow.
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li class="mb-10 ms-6 relative">
                        <span class="absolute right-[calc(100%+10px)] inline-block pr-10 top-2">
                           <time class="block mb-2 text-xs md:text-sm font-normal leading-none text-gray-400 whitespace-nowrap">November 2024</time>
                        </span>
                        <span class="absolute flex items-center justify-center w-6 h-6 rounded-full top-1 -start-9 ring-8 ring-white dark:ring-gray-900 dark:bg-slate-900">
                           <i class="fa-regular text-lg fa-calendar-day text-gray-400"></i>
                        </span>
                        <h3 class="flex items-center mb-2 text-lg font-semibold text-gray-900 dark:text-white">Testing and Quality Assurance (QA)</h3>
                        <div class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                            <ul class="space-y-2 text-gray-400 text-sm sm:text-normal list-disc list-inside marker:text-gray-200">
                                <li>
                                    Complete unit, integration, and end-to-end testing across all core features (authentication, vaults, 2FA, bots, security dashboard).
                                </li>
                                <li>
                                    Conduct security testing to ensure data integrity and protection.
                                </li>
                                <li>
                                    Test performance and scalability for handling multiple users and large volumes of credentials.
                                </li>
                                <li>
                                    Fix bugs, optimize performance, and ensure system stability.
                                </li>
                            </ul>
                        </div>
                    </li>


                    <li class="mb-10 ms-6 relative">
                        <span class="absolute right-[calc(100%+10px)] inline-block pr-10 top-2">
                           <time class="block mb-2 text-xs md:text-sm font-normal leading-none text-gray-400 whitespace-nowrap">January 2025</time>
                        </span>
                        <span class="absolute flex items-center justify-center w-6 h-6 rounded-full top-1 -start-9 ring-8 ring-white dark:ring-gray-900 dark:bg-slate-900">
                           <i class="fa-regular text-lg fa-calendar-day text-gray-400"></i>
                        </span>
                        <h3 class="flex items-center mb-2 text-lg font-semibold text-gray-900 dark:text-white">Private Beta Launch</h3>
                        <div class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                            <ul class="space-y-2 text-gray-400 text-sm sm:text-normal list-disc list-inside marker:text-gray-200">
                                <li>
                                    Release the private beta to a select group of users for real-world testing.
                                </li>
                                <li>
                                    Collect feedback on usability, performance, and bugs.
                                </li>
                                <li>
                                    Monitor security logs and address any potential vulnerabilities.
                                </li>
                                <li>
                                    Refine the product based on feedback and beta testing results.
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li class="mb-10 ms-6 relative">
                        <span class="absolute right-[calc(100%+10px)] inline-block pr-10 top-2">
                           <time class="block mb-2 text-xs md:text-sm font-normal leading-none text-gray-400 whitespace-nowrap">February 2025</time>
                        </span>
                        <span class="absolute flex items-center justify-center w-6 h-6 rounded-full top-1 -start-9 ring-8 ring-white dark:ring-gray-900 dark:bg-slate-900">
                           <i class="fa-regular text-lg fa-calendar-day text-gray-400"></i>
                        </span>
                        <h3 class="flex items-center mb-2 text-lg font-semibold text-gray-900 dark:text-white">Whatsapp Integration</h3>
                        <div class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                            <ul class="space-y-2 text-gray-400 text-sm sm:text-normal list-disc list-inside marker:text-gray-200">
                                <li>
                                    Build out Whatsapp integration with the Bot service.
                                </li>
                                <li>
                                    Research and implement best UX flow for Whatsapp integration.
                                </li>
                                <li>
                                    Integrate Whatsapp platform onboarding and OAuth flow.
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li class="mb-10 ms-6 relative">
                        <span class="absolute right-[calc(100%+10px)] inline-block pr-10 top-2">
                           <time class="block mb-2 text-xs md:text-sm font-normal leading-none text-gray-400 whitespace-nowrap">April 2025</time>
                        </span>
                        <span class="absolute flex items-center justify-center w-6 h-6 rounded-full top-1 -start-9 ring-8 ring-white dark:ring-gray-900 dark:bg-slate-900">
                           <i class="fa-regular text-lg fa-calendar-day text-gray-400"></i>
                        </span>
                        <h3 class="flex items-center mb-2 text-lg font-semibold text-gray-900 dark:text-white">Google Workspace Integration</h3>
                        <div class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                            <ul class="space-y-2 text-gray-400 text-sm sm:text-normal list-disc list-inside marker:text-gray-200">
                                <li>
                                    Build out Google integration with the Bot service.
                                </li>
                                <li>
                                    Research and implement best UX flow for Google integration.
                                </li>
                                <li>
                                    Integrate Google platform onboarding and OAuth flow.
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li class="mb-10 ms-6 relative">
                        <span class="absolute right-[calc(100%+10px)] inline-block pr-10 top-2">
                           <time class="block mb-2 text-xs md:text-sm font-normal leading-none text-gray-400 whitespace-nowrap">June 2025</time>
                        </span>
                        <span class="absolute flex items-center justify-center w-6 h-6 rounded-full top-1 -start-9 ring-8 ring-white dark:ring-gray-900 dark:bg-slate-900">
                           <i class="fa-regular text-lg fa-calendar-day text-gray-400"></i>
                        </span>
                        <h3 class="flex items-center mb-2 text-lg font-semibold text-gray-900 dark:text-white">Microsoft Teams Integration</h3>
                        <div class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                            <ul class="space-y-2 text-gray-400 text-sm sm:text-normal list-disc list-inside marker:text-gray-200">
                                <li>
                                    Build out Microsoft integration with the Bot service.
                                </li>
                                <li>
                                    Research and implement best UX flow for Microsoft Teams integration.
                                </li>
                                <li>
                                    Integrate Microsoft platform onboarding and OAuth flow.
                                </li>
                            </ul>
                        </div>
                    </li>
                </ol>
            </div>
        </div>
    </div>

</section>


<section id="pricing">

    <div class="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div class="absolute inset-x-0 top-[250px] -z-10 transform-gpu overflow-hidden px-36 blur-3xl h-96" aria-hidden="true">
            <svg viewBox="0 0 1208 1024" aria-hidden="true" class="absolute -bottom-80 left-1/2 h-[64rem] -translate-x-1/2 translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] lg:-top-0 lg:bottom-auto lg:translate-y-0">
                <ellipse cx="604" cy="512" fill="url(#d25c25d4-6d43-4bf9-b9ac-1842a30a4867)" rx="604" ry="512" />
                <defs>
                    <radialGradient id="d25c25d4-6d43-4bf9-b9ac-1842a30a4867">
                        <stop stop-color="#FE491D" />
                        <stop offset="1" stop-color="#FE491D" />
                    </radialGradient>
                </defs>
            </svg>
        </div>
        <div class="mx-auto max-w-2xl text-center lg:max-w-4xl">
            <h2 class="font-inter font-bold text-center text-5xl mb-20">Pricing<span class="text-brand">.</span></h2>
            <p class="mt-2 font-inter text-2xl font-bold tracking-tight text-gray-900 sm:text-xl">Simple, structured offering which scales with you. Both plans below contain <span class="text-brand">all</span> of the features mentioned above. The right price for you, whoever you are.</p>
        </div>

        <div class="rounded-3xl overflow-hidden shadow-2xl bg-brand container max-w-[900px] mx-auto mt-20 text-white animate-wiggle">
            <div class="bg-gradient-to-t from-brand/95 to-brand/100 p-8 flex flex-col md:flex-row justify-between items-stretch">
                <div class="space-y-2 text-center md:text-left">
                    <div class="text-slate-950 font-bold">SPECIAL PRE-LAUNCH OFFER</div>
                    <div class="text-white">
                        <div class="text-xs">FROM</div>
                        <div class="text-5xl font-bold font-inter">
                            <passbot-multi-currency-price GBP="209" USD="275" EUR="251"></passbot-multi-currency-price> <span class="text-lg pl-2 font-normal text-white/80">For life</span>
                        </div>
                    </div>
                    <div class="text-gray-900 text-lg">
                        Back us today and get a <strong>lifetime</strong> account which works for you.
                    </div>

                </div>
                <div class="text-center text-slate-950 md:border-l md:border-s md:pl-10 pl-0 pt-8 md:pt-0 border-slate-950/30 flex flex-col justify-center space-y-2">
                    <div>
                        <a routerLink="/back-us" aria-describedby="tier-enterprise" class="inline-block transition-all rounded-md bg-slate-900 px-3.5 py-2.5 text-center text-2xl font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Buy Now</a>
                    </div>
                </div>

            </div>
        </div>
        <div class="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2 z-4">
            <div class="rounded-3xl rounded-t-3xl bg-white p-8 shadow-xl z-3 ring-1 ring-gray-900/10 sm:mx-8 sm:rounded-b-none sm:p-10 lg:mx-0 lg:rounded-bl-3xl lg:rounded-tr-none">
                <h3 id="tier-hobby" class="text-base font-semibold leading-7 text-brand">Team</h3>
                <p class="mt-4 flex items-baseline gap-x-2">
                    <span class="text-5xl font-bold tracking-tight text-gray-900">
                        <passbot-multi-currency-price GBP="3.49" USD="4.59" EUR="4.19"></passbot-multi-currency-price>
                    </span>
                    <span class="text-base text-gray-500">/user/month</span>
                </p>
                <p class="text-xs text-gray-600 mt-2">Minimum of 5 users on an annual billing basis.</p>
                <p class="mt-6 text-base leading-7 text-gray-600">The perfect plan if you&#039;re just getting started with our product.</p>
                <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600 sm:mt-10">
                    <li class="flex gap-x-3">
                        <svg class="h-6 w-5 flex-none text-brand" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                        </svg>
                        Unlimited Vaults
                    </li>
                    <li class="flex gap-x-3">
                        <svg class="h-6 w-5 flex-none text-brand" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                        </svg>
                        Unlimited Credentials
                    </li>
                    <li class="flex gap-x-3">
                        <svg class="h-6 w-5 flex-none text-brand" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                        </svg>
                        Single Workspace (Slack or Discord/Whatsapp/Teams e.t.c.)
                    </li>
                    <li class="flex gap-x-3">
                        <svg class="h-6 w-5 flex-none text-brand" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                        </svg>
                        Basic credential health check
                    </li>
                </ul>
                <a href="#" aria-describedby="tier-hobby" class="transition-all mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold text-brand ring-1 ring-inset ring-brand hover:bg-brand hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand sm:mt-10">Register Interest Today</a>
            </div>
            <div class="relative rounded-3xl bg-gray-900 p-8 shadow-2xl ring-1 ring-gray-900/10 sm:p-10">
                <h3 id="tier-enterprise" class="text-base font-semibold leading-7 text-brand">Agency</h3>
                <p class="mt-4 flex items-baseline gap-x-2">
                    <span class="text-5xl font-bold tracking-tight text-white">
                        <passbot-multi-currency-price GBP="4.99" EUR="5.99" USD="6.59"></passbot-multi-currency-price>
                    </span>
                    <span class="text-base text-gray-400">/user/month</span>
                </p>
                <p class="text-xs text-gray-400 mt-2">Minimum of 10 users on an annual billing basis.</p>
                <p class="mt-6 text-base leading-7 text-gray-300">Full support for multiple workspaces, and the ability to add users from different workspaces into shared vaults.</p>
                <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-300 sm:mt-10">
                    <li class="flex gap-x-3">
                        <svg class="h-6 w-5 flex-none text-brand" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                        </svg>
                        Unlimited Vaults
                    </li>
                    <li class="flex gap-x-3">
                        <svg class="h-6 w-5 flex-none text-brand" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                        </svg>
                        Unlimited Credentials
                    </li>
                    <li class="flex gap-x-3">
                        <svg class="h-6 w-5 flex-none text-brand" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                        </svg>
                        Multiple Workspaces (Slack & Discord, Whatsapp, Teams e.t.c)
                    </li>
                    <li class="flex gap-x-3">
                        <svg class="h-6 w-5 flex-none text-brand" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                        </svg>
                        Advanced Credential Health Check
                    </li>
                    <li class="flex gap-x-3">
                        <svg class="h-6 w-5 flex-none text-brand" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                        </svg>
                        Dark Web Monitoring for Credential Leaks
                    </li>
                </ul>
                <a href="#" aria-describedby="tier-enterprise" class="mt-8 block rounded-md bg-brand px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-brand/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 sm:mt-10">Register Interest Today</a>
            </div>
        </div>
    </div>
</section>


<section class="bg-slate-900" id="register">

    <div class="relative isolate">
        <div class="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
            <div class="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48 flex items-center">
                <div class="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                    <div class="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden lg:w-1/2">

                        <div class="absolute right-0 top-[calc(100%-15rem)] transform-gpu blur-4xl lg:right-0 lg:top-0 w-full h-full" aria-hidden="true">
                            <svg class="w-[900px] h-[900px] opacity-30 absolute right-0">
                                <ellipse cx="900" cy="450" fill="url(#brand-circle)" rx="400" ry="400" />
                                <defs>
                                    <radialGradient id="brand-circle">
                                        <stop stop-color="rgba(254, 73, 29, 100)" />
                                        <stop offset="1" stop-color="rgba(254, 73, 29, 0)" />
                                    </radialGradient>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <h2 class="text-3xl font-bold tracking-tight text-white">Register Interest <span class="text-brand">.</span></h2>
                    <p class="mt-6 text-lg leading-8 text-gray-300">If you have any interest in our product, let us know and we will send you an invite to our private beta later this year as well as keep you updated with our progress.</p>
                    <dl class="mt-10 font-normal leading-7 text-gray-300 flex justify-between items-start">
                        <div class="text-gray-200 flex flex-col items-center w-16 sm:w-28">
                            <div class="text-5xl border-b border border-brand p-4 rounded">
                                <i class="fa-solid fa-user-secret text-brand"></i>
                            </div>
                            <div class="text-xs mt-2 text-center">PRIVATE BETA ACCESS</div>
                        </div>


                        <div class="text-gray-200 flex flex-col items-center w-16 sm:w-28">
                            <div class="text-5xl border-b border border-brand p-4 rounded">
                                <i class="fa-solid fa-newspaper text-brand"></i>
                            </div>
                            <div class="text-xs mt-2 text-center">REGULAR UPDATES VIA NEWSLETTER</div>
                        </div>

                        <div class="text-gray-200 flex flex-col items-center w-16 sm:w-28">
                            <div class="text-5xl border-b border border-brand p-4 rounded">
                                <i class="fa-solid fa-ban text-brand"></i>
                            </div>
                            <div class="text-xs mt-2 text-center">NO SPAM</div>
                        </div>
                    </dl>
                </div>
            </div>
            <div class="block sm:flex items-center justify-center">
                <form *ngIf="interestRegisteredStatus === 0" action="#" method="POST" class="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48" [formGroup]="interestForm" (ngSubmit)="registerInterest()">
                    <div class="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                        <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                            <div>
                                <label for="first-name" class="block text-sm font-semibold leading-6 text-white">First name</label>
                                <div class="mt-1">
                                    <input [ngClass]="{ 'ring-red-600': interestForm.controls.firstName.invalid && interestForm.controls.firstName.dirty }" formControlName="firstName" type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6">
                                </div>
                            </div>
                            <div>
                                <label for="last-name" class="block text-sm font-semibold leading-6 text-white">Last name</label>
                                <div class="mt-1">
                                    <input [ngClass]="{ 'ring-red-600': interestForm.controls.lastName.invalid && interestForm.controls.lastName.dirty }" formControlName="lastName" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6">
                                </div>
                            </div>
                            <div class="sm:col-span-2">
                                <label for="email" class="block text-sm font-semibold leading-6 text-white">Email</label>
                                <div class="mt-1">
                                    <input [ngClass]="{ 'ring-red-600': interestForm.controls.email.invalid && interestForm.controls.email.dirty }" formControlName="email" type="email" name="email" id="email" autocomplete="email" class="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6">
                                </div>
                            </div>
                            <div class="sm:col-span-2">
                                <label for="company" class="block text-sm font-semibold leading-6 text-white">Company</label>
                                <div class="mt-1">
                                    <input [ngClass]="{ 'ring-red-600': interestForm.controls.company.invalid && interestForm.controls.company.dirty }" formControlName="company" type="text" name="company" id="company" autocomplete="company" class="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6">
                                </div>
                            </div>
                            <div class="sm:col-span-2">
                                <label for="size" class="block text-sm font-semibold leading-6 text-white">Company Size</label>
                                <select [ngClass]="{ 'ring-red-600': interestForm.controls.companySize.invalid && interestForm.controls.companySize.dirty }" formControlName="companySize" id="size" name="size" class="mt-1 block w-full bg-white/5 rounded-md border-0 text-white px-3.5 py-2 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6">
                                    <option class="bg-slate-900" value="0-10">0-10 Employees</option>
                                    <option class="bg-slate-900" value="10-50">10-50 Employees</option>
                                    <option class="bg-slate-900" value="50-250">50-250 Employees</option>
                                    <option class="bg-slate-900" value="250+">250+ Employees</option>
                                </select>
                            </div>
                            <div class="sm:col-span-2">
                                <label for="industry" class="block text-sm font-semibold leading-6 text-white">Company Industry</label>
                                <select [ngClass]="{ '!ring-red-600': interestForm.controls.industry.invalid && interestForm.controls.industry.dirty }" formControlName="industry" id="industry" name="industry" class="mt-1 block w-full bg-white/5 rounded-md border-0 text-white px-3.5 py-2 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-brand sm:text-sm sm:leading-6">
                                    <option *ngFor="let industry of industries" class="bg-slate-900" [value]="industry">{{ industry }}</option>
                                </select>
                            </div>
                            <div class="sm:col-span-2">
                                <label for="workspace" class="block text-sm font-semibold leading-6 text-white">Main Workspace App</label>
                                <select [ngClass]="{ 'ring-red-600': interestForm.controls.workspace.invalid && interestForm.controls.workspace.dirty }" formControlName="workspace" id="workspace" name="workspace" class="mt-1 block w-full bg-white/5 rounded-md border-0 text-white px-3.5 py-2 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6">
                                    <option class="bg-slate-900">Slack</option>
                                    <option class="bg-slate-900">Discord</option>
                                    <option class="bg-slate-900">Google Workspace</option>
                                    <option class="bg-slate-900">Microsoft Teams</option>
                                    <option class="bg-slate-900">WhatsApp</option>
                                </select>
                            </div>
                        </div>
                        <div class="mt-8 text-red-600 font-semibold border border-red-600 text-center p-4" *ngIf="interestRegisteredStatus === 0 && signupError">
                            {{ signupError }}
                        </div>
                        <div class="mt-8 flex items-center">
                            <div class="flex-1">
                                <div class="font-semibold text-red-700" *ngIf="interestForm.invalid && interestForm.errors?.required">All fields are required</div>
                            </div>
                            <button [disabled]="interestForm.invalid" type="submit" class="rounded-md disabled:bg-gray-600 bg-brand px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-brand/60 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Register My Interest</button>
                        </div>
                    </div>
                </form>
                <div *ngIf="interestRegisteredStatus === 1">
                    <i class="fa-regular text-brand text-7xl fa-spinner fa-spin"></i>
                </div>
                <div *ngIf="interestRegisteredStatus === 2" class="px-8 space-y-4">
                    <div class="font-bold text-4xl text-brand tracking-tight">Thanks!</div>
                    <div class="font-semibold text-lg text-white">We will be in touch soon.</div>
                    <div class="text-lg text-white">Remember, for private beta access and other exlusive perks, including a fixed lifetime fee, you can <a class="text-brand font-semibold" href="/back-us">back us now</a>.</div>
                    <div (click)="resetForm()" class="text-brand text-xs">Reset form</div>
                </div>
            </div>

        </div>
    </div>

</section>
