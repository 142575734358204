import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SEOService } from './services/seo.service';

@NgModule({
    providers: [SEOService],
})
export class SEOModule {
    constructor(@Optional() @SkipSelf() parentModule?: SEOModule) {
        if (parentModule) {
            throw new Error('SEOModule is already loaded. Import it in the AppModule only');
        }
    }
}
