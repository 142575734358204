<section class="fixed h-14 w-full top-0 left-0 bg-slate-900 p-2 pl-4 font-inter z-40 ">
    <div class="flex h-full items-center space-x-2 container mx-auto">
        <div class="text-brand font-semibold text-sm flex-1 gap-0 divide-x align-middle divide-brand/40 hidden lg:flex">
            <a (click)="scrollTo('features')" class="flex items-center justify-center cursor-pointer pr-4">Features</a>
            <a (click)="scrollTo('milestones')" class="flex items-center justify-center text-center cursor-pointer px-4">Progress & Milestones</a>
            <a (click)="scrollTo('pricing')"  class="flex items-center justify-center cursor-pointer pl-4">Pricing</a>
        </div>


        <div class="flex-1 block lg:hidden" (click)="toggleMobileMenu()">
            <i class="fa-solid fa-bars text-2xl text-brand"></i>
        </div>

        <div class="">
            <div class="relative group" *ngIf="currency$ | async as currency">
                <button type="button" class="relative peer w-full cursor-default rounded-md bg-none py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm hover:ring-1 ring-inset hover:ring-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
                      <span class="flex items-center space-x-2 text-white/80">
                          <span class="w-5 h-5 bg-brand flex items-center justify-center rounded-full font-bold text-slate-900">
                              <ng-container *ngIf="currency === 'USD'">&dollar;</ng-container>
                              <ng-container *ngIf="currency === 'GBP'">&pound;</ng-container>
                              <ng-container *ngIf="currency === 'EUR'">&euro;</ng-container>
                          </span>
                          <span>{{ currency }}</span>
                      </span>
                      <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                        <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                        </svg>
                      </span>
                </button>

                                <!--
                                  Select popover, show/hide based on select state.

                                  Entering: ""
                                    From: ""
                                    To: ""
                                  Leaving: "transition ease-in duration-100"
                                    From: "opacity-100"
                                    To: "opacity-0"
                                -->
                <ul class="absolute transition-all  group-hover:block hidden z-10 max-h-56 w-full overflow-auto rounded-md bg-slate-800 text-base shadow-lg ring-1 ring-slate-900 ring-opacity-5 focus:outline-none sm:text-sm" tabindex="-1" role="listbox" aria-labelledby="listbox-label">
                    <li (click)="changeCurrency('USD')" *ngIf="currency !== 'USD'" class="cursor-pointer hover:bg-slate-700 relative flex space-x-2 items-center justify-center py-2 text-gray-200">
                         <span class="w-5 h-5 bg-brand flex items-center justify-center rounded-full font-bold text-slate-900">
                              &dollar;
                          </span>
                        <span>USD</span>
                    </li>

                    <li (click)="changeCurrency('GBP')" *ngIf="currency !== 'GBP'" class="cursor-pointer hover:bg-slate-700 relative flex space-x-2 items-center justify-center py-2 text-gray-200" role="option">
                        <span class="w-5 h-5 bg-brand flex items-center justify-center rounded-full font-bold text-slate-900">
                              &pound;
                          </span>
                        <span>GBP</span>
                    </li>

                    <li (click)="changeCurrency('EUR')" *ngIf="currency !== 'EUR'" class="cursor-pointer hover:bg-slate-700 relative flex space-x-2 items-center justify-center py-2 text-gray-200" role="option">
                        <span class="w-5 h-5 bg-brand flex items-center justify-center rounded-full font-bold text-slate-900">
                              &euro;
                          </span>
                        <span>EUR</span>
                    </li>

                    <!-- More items... -->
                </ul>
            </div>
        </div>

        <div>
            <a (click)="scrollTo('register')" class="text-sm sm:text-normal cursor-pointer py-2 px-4 text-center rounded-lg font-bold border border-s border-brand text-brand transition-all hover:bg-brand hover:text-slate-950 hidden sm:block">REGISTER NOW</a>
        </div>
        <div>
            <a routerLink="/back-us" class="text-sm sm:text-normal cursor-pointer hover:bg-brand/80 border border-black text-slate-900 py-2 px-4 text-center rounded-lg font-bold bg-brand">BACK US<span class="hidden sm:inline"> NOW</span></a>
        </div>
    </div>
</section>

<section [ngClass]="{'left-0 ': mobileMenuVisible, '-left-[100%]': !mobileMenuVisible}" class="fixed transition-all h-[calc(100%-56px)] bottom-0 w-full bg-slate-900 z-50 flex flex-col justify-center items-center">
    <div class="text-brand mt-10 font-inter font-bold text-2xl" (click)="mobileMenuVisible = false" [routerLink]="'/'">
        <img class="h-14 w-auto inline-block md:hidden mr-4" src="/assets/images/passbot-logo.svg"/> Passbot.io
    </div>
    <div class="flex-1 flex justify-center items-center">
        <div class="grid grid-cols-1 divide-brand/40 divide-y text-brand text-2xl sm:text-4xl font-semibold -mt-10">
            <a (click)="scrollTo('features', true)" class="flex items-center justify-center cursor-pointer pb-6">Features</a>
            <a (click)="scrollTo('milestones', true)" class="flex items-center justify-center text-center cursor-pointer py-6">Progress & Milestones</a>
            <a (click)="scrollTo('pricing', true)"  class="flex items-center justify-center cursor-pointer pt-6 pb-6 sm:pb-0">Pricing</a>
            <a (click)="scrollTo('register', true)"  class="flex items-center justify-center cursor-pointer pt-6 sm:hidden font-bold">Register Interest</a>
        </div>
    </div>
</section>

<router-outlet>


</router-outlet>


<footer class="bg-slate-900">
    <div class="container mx-auto p-4 text-white/40 grid md:grid-cols-3 gap-2 align-middle">
        <div class="text-xs text-center md:text-left order-3 md:order-1 flex flex-col justify-center">
            <a routerLink="/privacy-policy" class="text-sm text-brand">Privacy Policy</a>
            &copy; Endpoint Esports Ltd
        </div>
        <div class="order-2 flex items-center space-x-2 justify-center my-4 md:my-0">
            <span>For all enquiries:</span> <a href="mailto:hello@passbot.io" class="text-brand">hello&#64;passbot.io</a>
        </div>
        <div class="space-x-2 text-center md:text-right order-1 md:order-3 flex md:justify-end justify-center items-center">
            <a href="https://discord.gg/uB8zzkfW" target="_blank" class="p-2 text-lg border border-discord rounded w-12 h-12 text-center inline-block text-discord hover:bg-discord hover:text-slate-900"><i class="fa-brands fa-discord"></i></a>
            <a href="https://www.linkedin.com/company/passbot.io" target="_blank" class="p-2 text-lg border border-linkedin rounded w-12 h-12 text-center inline-block text-linkedin hover:bg-linkedin hover:text-slate-900"><i class="fa-brands fa-linkedin-in"></i></a>
            <a href="https://twitter.com/passbotio" target="_blank" class="p-2 text-lg border border-twitter rounded w-12 h-12 text-center inline-block text-twitter hover:bg-twitter hover:text-slate-900"><i class="fa-brands fa-twitter"></i></a>
        </div>

    </div>
</footer>
