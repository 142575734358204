import { Routes } from '@angular/router';
import { IndexComponent } from './components/index/index.component';

export const APP_ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: IndexComponent,
        title: 'Passbot.io | Seamless Integrated Password Manager',
    },
    {
        path: 'back-us',
        loadComponent: () => import('./components/back-us/back-us.component').then((m) => m.BackUsComponent),
        title: 'Passbot.io | Back Us Today',
        data: {
            meta: {
                description:
                    'Secure your lifetime access to Passbot.io with our exclusive pre-launch offer. Enjoy early access to private beta, lifetime upgrades, and premium support as part of this limited-time deal for our all-in-one password manager integrated with Slack, Discord, and more.',
            },
        },
    },
    {
        path: 'privacy-policy',
        loadComponent: () => import('./components/privacy-policy/privacy-policy.component').then((m) => m.PrivacyPolicyComponent),
        title: 'Passbot.io | Privacy Policy',
    },
    {
        path: 'lifetime-terms-and-conditions',
        loadComponent: () =>
            import('./components/lifetime-terms-and-conditions/lifetime-terms-and-conditions.component').then(
                (m) => m.LifetimeTermsAndConditionsComponent,
            ),
        title: 'Passbot.io | Lifetime Account Terms & Conditions',
    },
    {
        path: '**',
        redirectTo: '/',
        pathMatch: 'full',
    },
];
