import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading, withRouterConfig } from '@angular/router';
import { provideNgcCookieConsent } from 'ngx-cookieconsent';
import { provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ConfigModule, ConfigService } from '@passbot/angular/config';
import { SEOModule, SEOService } from '@passbot/angular/seo';
import { environment } from '../environments/environment';
import { APP_ROUTES } from './app.routes';
import { LocationService } from './services/location.service';
import { CurrencyService } from './services/currency.service';
import { TrackingService } from './services/tracking.service';

export const appConfig: ApplicationConfig = {
    providers: [
        provideHttpClient(),
        provideAnimations(),
        provideRouter(
            APP_ROUTES,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({
                scrollPositionRestoration: 'top',
            }),
            withRouterConfig({
                paramsInheritanceStrategy: 'always',
            }),
        ),
        provideNgcCookieConsent({
            cookie: {
                domain: 'dev.passbot.io',
            },
            position: 'bottom-left',
            theme: 'classic',
            palette: {
                popup: {
                    background: '#000000',
                    text: '#ffffff',
                    link: '#ffffff',
                },
                button: {
                    background: '#fe491d',
                    text: '#000000',
                    border: 'transparent',
                },
            },
            type: 'info',
            content: {
                message: 'This website uses cookies to ensure you get the best experience on our website.',
                dismiss: 'Got it!',
                deny: 'Refuse cookies',
                link: 'Learn more',
                href: '/privacy-policy',
                policy: 'Cookie Policy',
            },
        }),
        importProvidersFrom(ConfigModule),
        importProvidersFrom(SEOModule),
        LocationService,
        CurrencyService,
        TrackingService,
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: ConfigService, seoService: SEOService) => () => {
                configService.loadConfig(environment);
            },
            deps: [ConfigService, SEOService],
            multi: true,
        },
    ],
};
