import { CommonModule, NgClass } from '@angular/common';
import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { filter, map, Subscription } from 'rxjs';

import { Currency, CurrencyService } from './services/currency.service';
import { TrackingService } from './services/tracking.service';

@Component({
    standalone: true,
    imports: [NgClass, CommonModule, RouterOutlet, RouterLink],
    selector: 'passbot-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnDestroy {
    public currency$ = this.currencyService.getCurrency();
    public mobileMenuVisible = false;
    public navigationEnd$: Subscription;

    constructor(
        private readonly route: Router,
        private readonly activatedRoute: ActivatedRoute,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        private readonly ccService: NgcCookieConsentService,
        private readonly currencyService: CurrencyService,
        private readonly trackingService: TrackingService,
    ) {}

    public ngAfterViewInit() {
        this.navigationEnd$ = this.route.events.pipe(
            filter((e) => e instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                if (route.firstChild) {
                    return route.firstChild;
                }

                return route;
            }),
            filter((route) => route.outlet === 'primary'),
        ).subscribe((route) => {
            this.trackingService.pageViewEvent(route);
        });
    }

    public ngOnDestroy() {
        this.navigationEnd$.unsubscribe();
    }

    public toggleMobileMenu() {
        this.mobileMenuVisible = !this.mobileMenuVisible;
    }

    public changeCurrency(currency: string) {
        this.currencyService.setCurrency(currency as Currency);
    }

    public async scrollTo(id: string, mobile = false) {
        if ((this.activatedRoute.snapshot as any)._routerState.url !== '/') {
            await this.route.navigateByUrl('/');
            setTimeout(async () => this.scrollTo(id, mobile), 200);
        }

        if (mobile) {
            this.mobileMenuVisible = false;
            setTimeout(() => {
                document.getElementById(id)!.scrollIntoView({
                    behavior: 'smooth',
                });
            }, 300);
            return;
        }

        document.getElementById(id)!.scrollIntoView({
            behavior: 'smooth',
        });
    }
}
